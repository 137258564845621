export const SIDEBAR_ITEMS = {
  'user-management': [
    {
      href: '/pengaturan-akses/satuan-pendidikan',
      label: 'Satuan Pendidikan',
    },
    {
      href: '/pengaturan-akses/dinas-kabupaten-kota',
      label: 'Dinas Kabupaten/Kota',
    },
    {
      href: '/pengaturan-akses/dinas-provinsi',
      label: 'Dinas Provinsi',
    },
  ],
}
