export const assetUrl = process.env.NEXT_PUBLIC_ASSET_URL
export const isDevelopment = process.env.NEXT_PUBLIC_ENV === 'development'

export const EMAIL_REGEX =
  /^([!#-'*+\/-9=?A-Z^-~-]+(\.[!#-'*+\/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+\/-9=?A-Z^-~-]+(\.[!#-'*+\/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])$/i
export const EMAIL_DINAS_REGEX =
  /^([!#-'*+\/-9=?A-Z^-~-]+(\.[!#-'*+\/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@dinas\.belajar\.id$/i

export enum BulkAddAccessStatusEnum {
  IN_QUEUE = 'in_queue',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  SUCCESS = 'success',
  PARTIAL_SUCCESS = 'partial_success',
}

export const BULK_ADD_STATUS_MAP = {
  [BulkAddAccessStatusEnum.IN_PROGRESS]: 'Diproses',
  [BulkAddAccessStatusEnum.IN_QUEUE]: 'Menunggu Diproses',
  [BulkAddAccessStatusEnum.PARTIAL_SUCCESS]: 'Berhasil Sebagian',
  [BulkAddAccessStatusEnum.FAILED]: 'Gagal',
  [BulkAddAccessStatusEnum.SUCCESS]: 'Berhasil',
}

export const BULK_ADD_STATUS_COLOR = {
  [BulkAddAccessStatusEnum.IN_PROGRESS]: 'informational',
  [BulkAddAccessStatusEnum.IN_QUEUE]: 'neutral',
  [BulkAddAccessStatusEnum.PARTIAL_SUCCESS]: 'warning',
  [BulkAddAccessStatusEnum.FAILED]: 'critical',
  [BulkAddAccessStatusEnum.SUCCESS]: 'success',
}
