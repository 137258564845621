import { useRouter } from 'next/router'
import React from 'react'

import { Text } from '@rapor-pendidikan/text'

import { useAuth } from 'utils/auth'

import { RaporPendidikanAdminLayout } from 'components/Layout'
import { Seo } from 'components/Seo'

import { assetUrl } from 'configs/constant'
import { initToggleFeatures } from 'configs/toggle-feature'

import type { ToggleFeature } from 'types/toggle-features'

export const App = ({ toggleFeatureList }) => {
  const { session } = useAuth()
  const router = useRouter()
  if (!session) {
    router.replace('/login')
  }

  return (
    <>
      <Seo />

      <RaporPendidikanAdminLayout toggleFeatureList={toggleFeatureList}>
        <div style={{ maxWidth: 1028, margin: '0 auto' }}>
          <picture>
            <img
              width={84}
              height={84}
              src={`${assetUrl}images/img-handshake.png`}
              alt="handshake image"
            />
          </picture>
          <Text as="h1" variant="display-md" className="mt-6">
            Selamat datang di Dashboard Admin
            {session && ', ' + session.user.name + '!'}
          </Text>
        </div>
      </RaporPendidikanAdminLayout>
    </>
  )
}

export async function getServerSideProps() {
  const toggleFeatureList: ToggleFeature[] = await initToggleFeatures()

  return {
    props: { toggleFeatureList },
  }
}

export default App
