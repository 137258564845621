export const getValue = (key: string, initialValue?) => {
  try {
    if (typeof window !== 'undefined') {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    }
    return initialValue
  } catch (error) {
    // eslint-disable-next-line
    console.log('error in local storage key:', key)
    // eslint-disable-next-line
    console.error(error)
    return initialValue
  }
}

export const setValue = (key: string, value) => {
  try {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log('error in local storage key:', key)
    // eslint-disable-next-line
    console.error(error)
  }
}
