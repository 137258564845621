import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { stringify } from 'query-string'

import { setValue } from 'utils/storage'

import { api as createAxios, baseApiUrl, guruApiUrl } from 'configs/api'
import { COMMON_FORBIDDEN, COMMON_UNAUTHORIZED } from 'configs/api-constants'
import { APP_LOGIN_STORAGE_KEY } from 'configs/auth'

import { getValue } from './storage'

import type { ApiErrorResponse } from 'types/api'

const paramsSerializer = (params?: Record<string, any>): string =>
  stringify(params ?? {}, { arrayFormat: 'none' })

const authError = [401, 403, COMMON_UNAUTHORIZED, COMMON_FORBIDDEN]
export const isAuthError = (response: ApiErrorResponse): boolean => {
  const errorCode = response?.code
  return authError.includes(errorCode)
}

export async function api<T>(
  path: string,
  options: AxiosRequestConfig = {},
  storageKey: string = APP_LOGIN_STORAGE_KEY
): Promise<T> {
  const session = getValue(storageKey)
  if (session?.guruToken) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${session.guruToken}`,
    }
  }

  const requestApi = (): AxiosPromise<T> => {
    const url = `${baseApiUrl}${path}`

    return axios({
      ...options,
      paramsSerializer,
      url,
    })
  }

  const response = await requestApi()
  return response.data
}

/**
 * Declares axios interceptor globally
 * Intercepts 401 requests, assuming current token expired to fetch a new one
 * On refresh failure, calls the provided callback
 * On refresh success, resend previous failed request
 * On error, calls provided callback
 * @param { onExpired, onRefresh, onError } - call back functions
 */
export function applyAxiosInterceptor({
  onExpired,
  onRefresh,
  onError,
}: {
  onExpired?: () => void
  onRefresh?: (guruToken: string) => void
  onError?: (errorCode: any, response: any) => void
} = {}) {
  let refreshInstance: Promise<any> | null
  let isRefreshing = false
  /**
   * Refresh token mechanism on client side
   */
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalReq = error.config
      const code = error.response?.data?.code || error.response?.status
      const isUnauthorized = code === COMMON_UNAUTHORIZED || code === 401

      // Only apply to auth error and rapor be requests
      if (isUnauthorized && originalReq.url.includes(baseApiUrl)) {
        if (!error.config.__retry) {
          error.config.__retry = true
          if (!isRefreshing) {
            isRefreshing = true
            // Create new axios instance so interceptors won't affect it
            refreshInstance = createAxios()
              .post(
                `${guruApiUrl}/teachers/v1alpha2/guru-token/refresh`,
                {},
                { headers: originalReq.headers }
              )
              .then(({ data }) => {
                const { guruToken, user } = data || {}
                // Refresh successful but no loginEmail -> logout user
                if (!!!user?.loginEmail) {
                  return Promise.reject()
                }
                // Update token
                setValue(APP_LOGIN_STORAGE_KEY, data)
                onRefresh?.(guruToken)
                return Promise.resolve(data)
              })
              .catch(() => {
                // eslint-disable-next-line no-console
                console.log('Axios Interceptor - User not authorized')
                onExpired?.()
                return Promise.reject(error)
              })
              .finally(() => {
                isRefreshing = false
                refreshInstance = null
              })
          }

          return refreshInstance
            .then((session) => {
              originalReq.headers[
                'Authorization'
              ] = `Bearer ${session.guruToken}`
              return axios(originalReq)
            })
            .catch(() => Promise.reject(error))
        }

        onExpired?.()
      } else if (originalReq.url.includes(baseApiUrl)) {
        onError?.(code, error.response)
      }

      return Promise.reject(error)
    }
  )
}
