import clsx from 'clsx'
import { useRouter } from 'next/router'
import * as React from 'react'

import { Icon } from '@rapor-pendidikan/icon'
import { Navbar } from '@rapor-pendidikan/navbar'
import { Text } from '@rapor-pendidikan/text'
import { useToast } from '@rapor-pendidikan/toast'
import { VerticalNavigation } from '@rapor-pendidikan/vertical-navigation'

import { applyAxiosInterceptor } from 'utils/api'
import { getSession, useAuth } from 'utils/auth'

import { COMMON_FORBIDDEN } from 'configs/api-constants'
import { assetUrl } from 'configs/constant'

import { SIDEBAR_ITEMS } from './data'

import { ToggleFeature } from 'types/toggle-features'

const generateItemByRole = (childrenItem: any) => {
  const session = getSession()
  if (childrenItem.dynamicItems && session) {
    const { user } = session
    const { dynamicItems } = childrenItem
    const { groups } = user
    const userRole = groups.find((group) => dynamicItems[group.name])
    return userRole ? dynamicItems[userRole.name] : childrenItem
  }
  return childrenItem
}

const navigationItems = (currentPathname: string) => {
  const getChildren = (sidebarKey: 'user-management' | 'metadata') => {
    const childrenItems = SIDEBAR_ITEMS[sidebarKey]
    return childrenItems.map((item) => ({
      isActive: currentPathname.includes(item.href),
      ...generateItemByRole(item),
    }))
  }

  const sidebarItems = [
    {
      children: [],
      href: '/',
      icon: <Icon>home</Icon>,
      isActive: currentPathname === '/',
      label: 'Beranda',
    },
  ]

  // Add User Access Management Items
  // If all of its feature are being turned off, the parent category should not be rendered
  const userAccessChildren = getChildren('user-management')
  userAccessChildren.length > 0 &&
    sidebarItems.push({
      children: userAccessChildren,
      href: '#',
      icon: <Icon>supervisor_account</Icon>,
      isActive: currentPathname.includes('/pengaturan-akses'),
      label: 'Pengaturan Akses',
    })

  return sidebarItems
}

const CustomLink = (props) => {
  const router = useRouter()
  const { pathname } = router
  const { href, children } = props

  // If it is on root, check by using pathname === href, not using includes
  const isActive = href === '/' ? pathname === href : pathname.includes(href)

  return (
    <div style={{ backgroundColor: isActive && 'rgba(255, 255, 255, 0.1)' }}>
      {children}
    </div>
  )
}

export interface RaporPendidikanAdminLayoutProps {
  fullWidth?: boolean
  children?: React.ReactNode
  toggleFeatureList?: ToggleFeature[]
}

const profileItem = [
  {
    href: '/logout',
    text: 'Keluar',
    id: 'logout',
    active: false,
  },
]

export const RaporPendidikanAdminLayout = (
  props: RaporPendidikanAdminLayoutProps
) => {
  const { fullWidth = false, children } = props
  const router = useRouter()
  const { pathname } = router
  const { session } = useAuth()
  const toast = useToast()
  const [sessionExpired, setSessionExpired] = React.useState(false)

  if (!session) {
    router.replace('/login')
  }

  React.useEffect(() => {
    applyAxiosInterceptor({
      onExpired: () => {
        if (!sessionExpired) {
          toast({
            message:
              'Sesi berakhir sehingga Anda keluar otomatis dari akun. Silakan masuk lagi.',
          })
          router.push({
            pathname: '/logout',
            query: {
              from: router.pathname !== '/logout' && router.pathname,
            },
          })
        }
        setSessionExpired(true)
      },
      onError(errorCode, response) {
        if (errorCode === COMMON_FORBIDDEN || errorCode === 403) {
          toast({
            message: response?.data?.message || 'Anda tidak memiliki akses',
          })
        }
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar
        username={session && session.user.name}
        email={session && session.user.email}
        profileItems={profileItem}
        avatar={session && session.user.picture}
        logoId="rapor-pendidikan-admin-logo"
        logoUrl={`${assetUrl}images/logo-rapor-pendidikan.png`}
      >
        <div className="flex items-center">
          <div className="flex h-12 items-center border-l border-disabled pl-4">
            <Text id="rapor-pendidikan-admin-text" variant="heading-sm">
              Admin
            </Text>
          </div>
        </div>
      </Navbar>
      <div className="flex w-full">
        <div
          className="z-10 w-[316px] flex-shrink-0"
          style={{ minHeight: 'calc(100vh - 68px)' }}
        >
          <VerticalNavigation
            className="fixed h-full w-[316px] bg-expressive-dark-blue p-3"
            customLink={CustomLink}
            items={navigationItems(pathname)}
            style={{
              boxShadow:
                '0 2px 1px rgba(0, 0, 0, .05), 0 0 1px rgba(0, 0, 0, .25)',
            }}
            inverse
          />
        </div>
        <main
          className={clsx('flex flex-1 flex-col pt-7', !fullWidth && 'px-12')}
          style={{
            minHeight: 'calc(100vh - 68px)',
            maxWidth: 'calc(100% - 316px)',
          }}
        >
          {children}
        </main>
      </div>
    </>
  )
}

export default RaporPendidikanAdminLayout
